body {
  margin: 0;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #00468b;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
