.home.my.name {
    margin-top: 50px;
    font-size: 2rem;
    font-family: 'Spartan', sans-serif;
    /* animation-name: nameAni;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; */
    color: papayawhip;
    margin-bottom: 40px;
    
    
}
/* @keyframes nameAni  {
 from{margin-top: -200px}
 to{margin-top: 35px}
} */

.home.my:hover {
    cursor: pointer;
}

.home.my.job.title {
    font-size: 3rem;
    color: papayawhip;
    
}



.ui.right.aligned.container.home {
   float: left;
}

.dark-mode {
    background-color:#bec2cb;
    
  }

  .projects.carousel:hover {
     cursor: pointer;
  }

 


        body { 
            animation: fadeInAnimation ease 6s; 
            animation-iteration-count: 1; 
            animation-fill-mode: forwards; 
        } 
        @keyframes fadeInAnimation { 
            0% { 
                opacity: 0; 
            } 
            100% { 
                opacity: 1; 
            } 
        } 



.indicating.scroll.down.icon {
      opacity: 0.5;
      animation-name: iconShow;
      animation-duration: 3s;
      animation-iteration-count: 1;
}

@keyframes iconShow {
    from{opacity: 0.5;}
    to{opacity: 1;}
}

.scroll.to.top.button{
    position: absolute;
    right: 0;
  
}

.scroll.to.top.button:hover{
    color: lightskyblue;
}

.about.me.section.for.animation{
    animation-name: sectionFadeIn;
    animation-duration: 5s;
    animation-iteration-count: 1;
}

@keyframes sectionFadeIn{
    from{opacity: 0;}
    to{opacity: 1;}
    
}

 .project.image.for.animation{
    left: -1000px;
    animation-name: projectSlideIn;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
} 

@keyframes projectSlideIn{
    from{left: -1000px;}
    to{left: 20px;}
}


